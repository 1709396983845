import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import React, { Fragment, useState } from 'react';
import { useWindowDimensions } from '../../hooks/useDimension';

import { signIn } from '../../api/auth';

import s from './index.module.scss';
import validations from '../../utils/validations';

import Seo from '../../components/Seo';
import Input from '../../components/input';
import Button from '../../components/button';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RadioInput from '../../components/radioInput/index';

const Login = ({ location }) => {
  const { handleSubmit, errors, register } = useForm();
  const { width } = useWindowDimensions();

  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState({
    status: false,
    msg: '',
  });

  const onSubmit = ({ email, password }) => {
    setLoading(true);
    setError({ ...err, status: false });

    signIn({ email, password })
      .then(() => {
        setLoading(false);

        if (location.state.previous) {
          return navigate(location.state.previous, {
            state: {
              experienceId: location.state.experienceId,
            },
          });
        }

        navigate('/home');
      })
      .catch((err) => {
        setLoading(false);

        switch (err.code) {
          case 'auth/invalid-email':
            return setError({ status: true, msg: 'Este email no es válido' });
          case 'auth/wrong-password':
            return setError({ status: true, msg: 'Contraseña incorrecta.' });
          case 'auth/user-not-found':
            return setError({
              status: true,
              msg: 'Este correo no está registrado',
            });
          case 'auth/too-many-requests':
            return setError({
              status: true,
              msg: 'Intente de nuevo más tarde.',
            });

          default:
            navigate('/404');
        }
      });
  };
  const toogleCheckbox = () => {
    //cambiamos el estilo del checkbox falso dependiendo de si el checkbox real esta activado o no
    setCheckbox(document.getElementById('terms').checked);
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Iniciar sesión en coronasunsetchile.com"
        title="Iniciar Sesión"
      />

      <Header title="Iniciar Sesión" />

      <div className={s.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={s.inputsContainer}>
          <h1>
            {width <= 768 ? 'Iniciar Sesión' : 'Escribe tu Email y Contraseña'}
          </h1>

          <Input
            width="30%"
            type="text"
            name="email"
            className="field"
            placeholder="Correo Electrónico"
            err={errors.email}
            referencia={register(validations.email)}
          />

          <Input
            width="30%"
            type="password"
            name="password"
            className="field"
            placeholder="Password"
            err={errors.password}
            referencia={register(validations.password)}
          />

          <label htmlFor="terms" className={s.check} onClick={toogleCheckbox}>
            <RadioInput
              id="terms"
              name="pass"
              className="checkbox"
              type="checkbox"
              styles={{ opacity: '0' }}
            />
            <div className={checkbox ? s.checkboxTrue : s.checkboxFalse}></div>
            <small>Recordar mis datos</small>
          </label>

          {err.status && <div className={s.err}>{err.msg}</div>}

          <div className={s.btnContainer}>
            <Button
              type="submit"
              className="auth"
              value="Iniciar Sesión"
              loading={loading}
              styles={{ background: '#ffc72c' }}
            />

            <Button
              type="submit"
              className="auth"
              value="Registrarme"
              onClick={() => navigate('/register')}
            />
          </div>

          <p className={s.pass} onClick={() => navigate('/recovery-password')}>
            ¿Olvidaste tu contraseña?
          </p>
        </form>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Login;
