import React from 'react';
import s from './index.module.scss';

const radioInput = ({
  id,
  name,
  className,
  value,
  styles,
  width,
  referencia,
  type,
}) => {
  return (
    <div className={s.container} style={{ ...styles, width: width || 'auto' }}>
      <input
        id={id ? id : name}
        name={name}
        ref={referencia}
        className={s[className]}
        type={type}
        value={value}
      />
    </div>
  );
};

export default radioInput;
